import { defineComponent, h } from 'vue'
import Config from '@/utils/config'

export const ConfigState = defineComponent({
  name: 'ConfigState',
  setup() {
    if (!import.meta.env.SSR) {
      return () => null
    }

    const config = Object.keys(Config).reduce((acc, key) => {
      if (key === 'env') return acc
      // @ts-ignore
      acc[key] = Config[key]
      return acc
    }, {})

    return () => h('script', { innerHTML: `window.Config = ${JSON.stringify(config)}` })
  }
})