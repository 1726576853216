import ApiService from '@/services/ApiService'
import type { Content } from '@/models/Content'
import type ApiResponse from '@/models/ApiResponse'

export default class ContentService {
  public static getContent(name: string): Promise<Content|undefined> {
    return ApiService.get<ApiResponse<Content>>("content/" + name)
      .then((response) => {
        return response.data
      })
  }
}