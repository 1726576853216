<template>
  <div ref="el" v-html="html" />
</template>

<script setup lang="ts">
// This component is needed because Vue SSR does not properly detect changes in v-html upon dehydrating.

import { defineProps, nextTick, onMounted, onServerPrefetch, ref } from 'vue'

const el = ref<HTMLElement | null>(null)

const props = defineProps({
  html: {
    type: String,
    required: true,
  },
})

onMounted(() => {
  if (!el.value) return
  nextTick(() => {
    el.value!.innerHTML = props.html
  })
})

onServerPrefetch(() => {
  if (!el.value) return
  el.value!.innerHTML = props.html
})
</script>