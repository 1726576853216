import process from 'node:process'

export default class Config {
  private static env: Record<string, any> = {}
  private static construct = this.init()

  static apiUrl = this.env.VITE_API_URL as string
  static baseUrl = this.env.VITE_BASE_URL as string
  static languages = ['de', 'en']
  static ssr = import.meta.env.SSR

  private static init() {
    if (import.meta.env.SSR && process.env.NODE_ENV === 'production') {
      Config.env = Object.keys(process.env)
        .filter((key) => key.startsWith('VITE_'))
        .reduce((env, key) => {
          // @ts-ignore
          env[key] = process.env[key]
          return env
        }, {})
    } else {
      Config.env = import.meta.env
    }
    return undefined
  }

  private static afterConstruct = (function() {
    // @ts-ignore
    if (!import.meta.env.SSR && window['Config']) {
      // @ts-ignore
      const config = window['Config'] as Record<string, any>
      config['ssr'] = false
      Object.keys(config).forEach(key => {
        // @ts-ignore
        Config[key] = config[key]
      })
    }
    return undefined
  })()
}
