import { defineStore } from 'pinia'
import { ref } from 'vue'
import Config from '@/utils/config'

export const useUserStore = defineStore(
  'user',
  () => {
    // state
    const language = ref<string>(
      (typeof navigator != 'undefined' && Config.languages.find((language) => language === navigator.language.slice(0, 2 )))
        ? navigator.language.slice(0, 2) : 'de'
    )
    const token = ref<string | undefined>(undefined)

    // getters
    const isLoggedIn = () => !!token.value

    // setters
    const setLanguage = (lang: string) => {
      language.value = lang
      if (!import.meta.env.SSR)
        document.cookie = `language=${lang};path=/`
    }

    return {
      language,
      token,
      isLoggedIn,
      setLanguage,
    }
  },
  { persist: !import.meta.env.SSR }
)
