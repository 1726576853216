import './assets/main.css'
import { i18n } from '@/i18n'

import { createSSRApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import App from './App.vue'
import VueGtag from 'vue-gtag'
import router from './router'

export const createApp = (request?: any) => {
  /**
   * use createSSRApp to render the Vue App on the server
   * and send it to the user to do the hydration process
   */
  const app = createSSRApp(App)

  const pinia = createPinia()
  pinia.use(piniaPluginPersistedstate)
  app.use(pinia)
  app.use(router)
  const acceptLanguage = request?.headers['accept-language']
  const languageCookie = request?.cookies?.language
  app.use(i18n(request?.url, languageCookie, acceptLanguage), {})

  if (import.meta.env.VITE_GOOGLE_ANALYTICS_TAG) {
    app.use(VueGtag, {
      config: { id: import.meta.env.VITE_GOOGLE_ANALYTICS_TAG },
    })
  }

  return {
    app,
    router,
  }
}
