import { createMemoryHistory, createRouter, createWebHistory } from 'vue-router'
import { type RouterOptions } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Config from '@/utils/config'

const router = createRouter({
  history: Config.ssr
    ? createMemoryHistory(import.meta.env.BASE_URL)
    : createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/:locale(.{2})?',
      children: [
        {
          path: '',
          name: 'home',
          component: HomeView
        },
        {
          path: 'consulting',
          name: 'consulting',
          component: import('../views/ConsultingView.vue')
        },
        {
          path: 'content/:name',
          name: 'content',
          component: () => import('../views/ContentView.vue')
        },
      ],
    },
  ],
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash
      }
    } else {
      return {
        top: 0,
        left: 0
      }
    }
  },
} as RouterOptions)

export default router
