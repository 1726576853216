<template>
  <teleport to="head">
    <MetaTags :meta-data-fn="metaDataFn" />
    <ConfigState />
  </teleport>
  <div class="max-w-full min-h-[100vh] flex flex-col">
    <Header />
    <main class="mt-8 md:mt-12 mb-16 md:mb-24 grow">
      <RouterView />
    </main>
    <Header />
    <Footer />
  </div>
</template>

<script setup lang="ts">
import { MetaTags } from '@/components/MetaTags'
import { useMeta } from '@/composables/Meta'
import { computed, getCurrentInstance, onServerPrefetch, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useUserStore } from '@/stores/UserStore'
import { ConfigState } from '@/components/ConfigState'
import Footer from '@/components/Footer.vue'
import Header from '@/components/Header.vue'
import Config from '@/utils/config'

const { metaDataFn, updateMetaData } = useMeta()
const { t } = useI18n()
const app = getCurrentInstance()
const fullUrl = app?.appContext.config.globalProperties.fullUrl
const userStore = useUserStore()
const route = useRoute()

const resetMetaData = () => {
  return updateMetaData({
    language: computed(() => userStore.language),
    title: computed(() => t('common.seo.title')),
    description: computed(() => t('common.seo.description')),
    image: computed(() => t('common.seo.image')),
    noIndex: computed(() => false),
    url: computed(() => (import.meta.env.SSR ? fullUrl : window.location.href)),
    next: computed(() => undefined),
    prev: computed(() => undefined),
  })
}

resetMetaData()

const language = computed((): string => {
  return (route.params.locale as string) ?? userStore.language ?? Config.languages?.[0]
})

onServerPrefetch(() => {
  if (!language.value) return
  userStore.setLanguage(language.value)
})

watch(
    () => route.path,
    () => {
      resetMetaData()
    }
)

watch(
  () => language.value,
  (newLanguage) => {
    if (!newLanguage) return
    userStore.setLanguage(newLanguage)
  },
  { immediate: true }
)
</script>
