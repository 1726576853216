<template>
  <div class="flex flex-col gap-8">
    <div class="max-w-full lg:ml-[440px] flex flex-col">
      <div class="max-w-[1200px] flex flex-col lg:flex-row gap-8 lg:gap-32">
        <div class="flex-grow">
          <h1 class="text-left">{{ t('home.heroTitle') }}</h1>
          <h3>{{ t('home.heroText') }}</h3>
          <RouterLink :to="{ name: 'consulting' }">
            <AppButton :label="t('home.talkButton')" />
          </RouterLink>
        </div>
        <div class="w-full lg:w-56">
          <img class="w-full rounded-xl" src="@/assets/images/app.webp" alt="App" />
        </div>
      </div>
    </div>

    <div class="w-[800px] max-w-full flex flex-col gap-8">
      <div class="lg:ml-[440px] w-full">
        <MultilangHtml v-if="content" :html="content?.content" />
        <div v-if="loading" class="mt-8 flex flex-row justify-center">
          <Spinner />
        </div>
      </div>
      <div class="lg:ml-[440px]">
        <h2>{{ t('home.talkTitle') }}</h2>
        <MultilangHtml :html="t('home.talkContent')" />
        <RouterLink :to="{ name: 'consulting' }">
          <AppButton :label="t('home.talkButton')" />
        </RouterLink>
      </div>
    </div>
    <div class="hidden lg:block pt-4 px-12 flex-shrink items-center" />
  </div>
</template>

<script setup lang="ts">
import MultilangHtml from '@/components/MultilangHtml.vue'
import { useI18n } from 'vue-i18n'
import AppButton from '@/components/AppButton.vue'
import ContentService from '@/services/ContentService'
import { onMounted, onServerPrefetch, ref } from 'vue'
import type { Content } from '@/models/Content'
import Spinner from '@/components/Spinner.vue'

const content = ref<Content | undefined>(undefined)
const loading = ref(false)
const { t } = useI18n()

onMounted(() => {
  loadContent('home')
})

onServerPrefetch(async () => {
  await loadContent('home')
})

const loadContent = async (name: string) => {
  content.value = undefined
  loading.value = true
  await ContentService.getContent(name).then((result) => {
    content.value = result
    loading.value = false
  }).catch(() => {
    content.value = { content: '', title: t('messages.contentNotFound')}
    loading.value = false
  })
}
</script>
