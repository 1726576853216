<template>
  <div class="flex flex-row gap-4 cursor-default select-none">
    <div class="w-4 h-4 spinner-animation rounded-full bg-[#741be0]" style="animation-delay: 0.0s" />
    <div class="w-4 h-4 spinner-animation rounded-full bg-[#477ddc]" style="animation-delay: 0.2s" />
    <div class="w-4 h-4 spinner-animation rounded-full bg-[#1be0d8]" style="animation-delay: 0.4s" />
  </div>
</template>

<style scoped>
@keyframes fadeAnimation {
  0% { opacity: 0; transform: scale(0); }
  30% { opacity: 1; transform: scale(1); }
  70% { opacity: 1; transform: scale(1); }
  100% { opacity: 0; transform: scale(0); }
}
.spinner-animation {
  animation: fadeAnimation 1.6s infinite;
  transform-origin: center;
}
</style>
