<template>
  <div class="p-8 bg-[#00000030] flex flex-col gap-4">
    <div class="flex flex-row gap-4 justify-center">
      <RouterLink
        v-for="language in languages"
        :key="language"
        class="m-2"
        :to="getRoute(language)"
      >
        {{ languageName(language) }}
      </RouterLink>
    </div>
    <div class="text-center">
      © ZNIPP GmbH
    </div>
  </div>
</template>
<script setup lang="ts">
import Config from '@/utils/config'
import { useRoute } from 'vue-router'
import { computed } from 'vue'

const languages = Config.languages
const route = useRoute()

const languageName = (language: string) => {
  const names = {
    de: 'Deutsch',
    en: 'English',
  }
  // @ts-ignore
  return names[language]
}

const plainRoute = computed(() => {
  return route.path.replace('/' + route.params.locale, '')
})

const getRoute = (language: string) => {
  return '/' + language + (plainRoute.value.startsWith('/') ? '' : '/') + plainRoute.value
}
</script>