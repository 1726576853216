<template>
  <div class="p-4 bg-[#00000030] flex flex-col md:flex-row justify-around items-center">
    <div>
      <RouterLink :to="{ name: 'home' }">
        <img src="@/assets/images/logo.png" alt="ZNIPP Logo" />
      </RouterLink>
    </div>
    <RouterLink
      v-for="item in menuItems" :key="item.route"
      class="p-2 text-2xl font-bold uppercase border-b-cyan-500"
      :class="{'border-b-4': route.path === item.route}"
      :to="item.route"
    >
      {{ t(item.textKey) }}
    </RouterLink>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

const { t } = useI18n()
const menuItems = [
  {
    route: '/',
    textKey: 'menu.home',
  },
  {
    route: '/consulting',
    textKey: 'menu.consulting',
  },
  {
    route: '/content/news',
    textKey: 'menu.news',
  },
  {
    route: '/content/blog',
    textKey: 'menu.blog',
  },
  {
    route: '/content/about',
    textKey: 'menu.about',
  },
  {
    route: '/content/case',
    textKey: 'menu.case',
  },
]
const route = useRoute()
</script>